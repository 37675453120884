import React from 'react';
import { format } from 'date-fns';
import {
  Typography,
  Card,
  CardContent,
  AccordionGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemContent
} from '@mui/joy';
import englishTranslations from '../locales/english.json';

const SurveyResponses = ({ responses }) => {
  const getEnglishLabel = key => {
    return englishTranslations.nodes[key]?.text || key;
  };

  const getEnglishValue = (key, value) => {
    if (Array.isArray(value)) {
      return value.map(v => getEnglishValue(key, v)).join(', ');
    }

    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    const options = englishTranslations.nodes[key]?.options;
    if (options && value) {
      const option = options.find(opt => opt.key === value);
      return option ? option.text : value;
    }

    return value;
  };

  const renderResponseEntries = response => {
    // If we have metadata with question order, use it
    if (response._metadata?.questionOrder) {
      return response._metadata.questionOrder.map(key => {
        const value = response[key];
        if (value === undefined) return null;

        return (
          <ListItem key={key}>
            <ListItemContent>
              <Typography level="body-sm">
                <strong>{getEnglishLabel(key)}:</strong>{' '}
                {getEnglishValue(key, value)}
              </Typography>
            </ListItemContent>
          </ListItem>
        );
      });
    }

    // Fallback to unordered entries if no metadata exists
    return Object.entries(response)
      .filter(
        ([key]) =>
          key !== 'timestamp' &&
          key !== 'id' &&
          key !== 'userId' &&
          key !== '_metadata'
      )
      .map(([key, value]) => (
        <ListItem key={key}>
          <ListItemContent>
            <Typography level="body-sm">
              <strong>{getEnglishLabel(key)}:</strong>{' '}
              {getEnglishValue(key, value)}
            </Typography>
          </ListItemContent>
        </ListItem>
      ));
  };

  return (
    <Card>
      <CardContent>
        <Typography level="title-md" gutterBottom>
          Survey responses
        </Typography>
        <AccordionGroup>
          {responses.length > 0 ? (
            responses.map(response => (
              <Accordion key={response.id}>
                <AccordionSummary>
                  {format(response.timestamp.toDate(), 'do MMMM yyyy')}
                </AccordionSummary>
                <AccordionDetails>
                  <List>{renderResponseEntries(response)}</List>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <Typography level="body-sm">No responses yet</Typography>
          )}
        </AccordionGroup>
      </CardContent>
    </Card>
  );
};

export default SurveyResponses;

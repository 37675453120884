import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate
} from 'react-router-dom';

import './modules/firebase';
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import Participants from './components/Participants';

import { UserProvider } from './context/user';
import Settings from './components/Settings';
import ErrorBoundary from './components/ErrorBoundary';
import Reports from './components/Reports';
import Overview from './components/Overview';
import ParticipantView from './components/ParticipantView';

const App = () => {
  return (
    <CssVarsProvider defaultMode="system">
      <CssBaseline />
      <UserProvider>
        <Outlet />
      </UserProvider>
    </CssVarsProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <LandingPage />
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          { index: true, element: <Navigate to="overview" replace /> },
          {
            path: 'overview',
            element: <Overview />
          },
          {
            path: 'participants',
            children: [
              { index: true, element: <Participants /> },
              { path: ':participantId', element: <ParticipantView /> }
            ]
          },
          {
            path: 'reports',
            element: <Reports />
          },
          {
            path: 'settings',
            element: <Settings />
          }
        ]
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
